import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Notifications from 'vue-notification'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Notifications) 

Vue.config.warnHandler = function (msg, vm, trace) {
  console.error(`Warn: ${msg}\nTrace: ${trace}`);
};

Vue.config.errorHandler = function (err, vm, info) {
  console.error(`Error: ${err.toString()}\nInfo: ${info}`);
};

//Vue.config.productionTip = false;



window.hostname = 'https://marketplace.clinicgo.cl/';
//window.hostname = 'http://localhost:8000/';
window.socket = 'https://socketserver.clinicgo.cl';

/* Vue.prototype.$ENV = ENV; */

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
