<template>
  <div id="app">    
    <div v-if="isInitialized">
      <router-view />
    </div>
    <div v-else>
      <img 
        :src="'https://assets-prestador-clinicgo.s3.sa-east-1.amazonaws.com/'+data_p.prestador_id+'/images/logo.png'" 
        style="max-height:80px; top: 50%; max-width: 200px; position: absolute; left:50%; transform: translate(-50%, -50%); " 
        alt="Imagen"
      >
    </div>
    <notifications
      position="top right"
      group="foo"
    />
  </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex';
import axios from 'axios';
import '@/assets/estilo_importado/vendor/fonts/fontawesome.css';
import '@/assets/estilo_importado/vendor/fonts/tabler-icons.css';

export default {
    components:{},
    data(){
      return{
        data_p:{},
        cargando:false,
        subdominios:{
        'integramedica':{prestador_id: 9},
        'neomedica':{prestador_id: 10},
        'cardiopro':{prestador_id: 11},
        'clini':{prestador_id: 4},
        'mediclic':{prestador_id: 1},
        'willbloom':{prestador_id: 5},
        'regonesi':{prestador_id: 8},
        'reley':{prestador_id: 7},
        'laboratorioblanco':{prestador_id: 13},
        'ucchristus':{prestador_id: 14},
        //'':{prestador_id: 9},
        '':{prestador_id: 1},
        'davila':{prestador_id: 15},
        //'':{prestador_id: 11},
        'admin':{prestador_id: 1},
        'ucchristus-admin-test':{prestador_id: 16},
        'sportsmd':{prestador_id: 18},

      }
      }
    },
    methods: {
    ...mapActions(['getAdmin', 'initialize', 'logoutAdmin']),
    changeFavicon(url) {
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = url;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    },
    computed: {
    ...mapGetters(['admin_datos', 'isLoggedIn', 'regiones', 'comunas', 'prestador']),
    isInitialized(){
      if (this.regiones.length > 0 &&  this.comunas.length > 0)
        return true;
      return false;
    }
    },
    created(){
      document.title = 'Cargando...';
      var hostname_ = window.location.hostname;
      //var href_ = window.location.href
      hostname_ = hostname_.replace('https://', '')
      var subdomain1 = hostname_.split('.').length > 2 ? hostname_.split('.')[0] : '';
      var subdomain2 = hostname_.split('.').length > 2 ? hostname_.split('.')[1] : '';
      var data_;

      if(subdomain2 in this.subdominios){
        data_ = this.subdominios[subdomain2]
      }
      else if(subdomain1 in this.subdominios){
        data_ = this.subdominios[subdomain1]
      }
      this.data_p = data_;
      this.initialize(data_);

    },
    mounted() {
      this.changeFavicon('https://assets-prestador-clinicgo.s3.sa-east-1.amazonaws.com/'+this.data_p.prestador_id+'/images/favicon.ico')
      this.ENV = process.env;
    },
    watch:{
    prestador(val){

      document.title = this.prestador.nombre+ ' - Administración';
      if(this.admin_datos.length == 0 && typeof(localStorage.token_admin) != "undefined") //Relogear si recarga la pagina
      {
        
        this.cargando = true;
        axios.defaults.headers.common['Authorization'] = localStorage.token_admin
        this.getAdmin({prestador_id:this.prestador.id}).then(e=>{this.cargando = false; this.$router.push('/Inicio') }).catch(e=>{this.cargando = false;});
        
      }
    },
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


#nav {
  padding: 30px;
}
.icon-edit{
  cursor: pointer;
}
.btn-ir{
  border: none;
  border-radius: 4px;
  padding: 2px 20px;
  color:#fff;
  cursor: pointer;
}
.btn-ir:hover{
  opacity: 0.8;
}
.btn-ir-gordo{
  border: none;
  border-radius: 4px;
  padding: 8px 20px;
  color:#fff;
  transition: 0.3s;
  cursor: pointer;
  display: inline-block;
}
.btn-ir-gordo:hover{
  opacity: 0.8;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

/* #nav a.router-link-exact-active {
  color: #42b983;
} */

.vue-notification-group{
  margin-top:120px !important;
}

.info-icon-container {
    position: relative;
    display: inline-block; /* Or block, depending on your layout needs */
}

.info-icon {
    cursor: pointer;
    /* Style your icon as needed */
}

.info-icon-container-popup {
    display: none;
    font-size: 14px;
    position: absolute;
    left: -20px; /* Adjust as necessary */
    bottom: 100%; /* Adjust popup position */
    width: 250px; /* Adjust width as necessary */
    background-color: white;
    border-radius: 4px;
    color: white;
    border: 1px solid #ddd; /* Optional */
    padding: 10px;
    z-index: 100;
    /* Add more styling as needed (e.g., shadows, rounded corners) */
}
.info-icon-container-popup::before {
    content: "";
    position: absolute;
   
    top: 100%; /* Position the arrow directly at the bottom of the popup */
    left: 30px; /* Center the arrow */
    margin-left: -5px; /* Half the width of the arrow to properly center it */
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent; /* Arrow color */
    /* Adjust the color to match the popup border or fill */
}

.info-icon-container:hover .info-icon-container-popup {
    display: block; /* Show the popup on hover */
}

</style>
