<!-- eslint-disable -->
<template>
<div>
<transition name="zoom-m">
   
  <div v-if="showing" id="modal-receta-m" >
    
  <div v-on:click="hide();" class="contenedor-icono">       
    <b-icon class="icon" icon="x"></b-icon>
  </div>
 <div v-if="loaded">
  <h2> Horario para {{ profesional.nombre }}</h2>

  <div class="main-m">

<div class="filtro">

   <h4>Infromación del horario</h4>
   <div >
            <div style="display:inline-block;">
<!--               <div style="margin-bottom:20px;">
                Nombre:
                <input
                  id=""
                  v-model="nombre"
                  type="text"
                  name=""
                >
              </div> -->
              <div style="margin-bottom:20px;">
                Intervalo                 
                <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
       Intervalos de tiempo que durará las atenciones, y que será mostrado en la agenda.
    </div>
</div>

                <select v-model="periodo">
                  <option
                    v-for="o in periodos_posibles"
                    :value="o"
                  >
                    {{ o }} minutos
                  </option>
                </select>
              </div>
              <table style="margin: 0; margin-bottom:20px">
                <td style="vertical-align:middle; padding-right:20px;">
                  <tr>
                    Inicio 
                    <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
       Inicio de la agenda, primera hora que se mostrara.
    </div>
</div>
                    
                  </tr>
                  <tr>
                    <select v-model="agregando_horario.inicio">
                      <option
                        v-for="v in Math.round(1440/5)"
                        :value="(v-1)*5"
                      >
                        {{ timeConvert((v-1)*5) }}
                      </option>
                    </select>
                  </tr>
                </td>
                <td style="vertical-align:middle; padding-right:20px;">
                  <tr>
                    Término
                    <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
       Término de la agenda, se mostrarán horas espaciadas por el intervalo seleccionado anteriormente, hasta que se llegue a este término.
    </div>
</div>
                  </tr>
                  <select v-model="agregando_horario.termino">
                    <option
                      v-for="v in Math.round((1440-agregando_horario.inicio)/periodo)"
                      :value="(v)*periodo + agregando_horario.inicio"
                    >
                      {{ timeConvert((v)*periodo + agregando_horario.inicio) }}
                    </option>
                  </select>
                </td>
                        
                <td style="vertical-align:middle;">
                  <table>
                    <tr>
                      <td
                        v-for="x in dias"
                        style="text-align:center; width:30px;"
                      >
                        {{ x }}
                      </td>
                      <td>
                        <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
       Días para los cuales estará habilitado el horario.
    </div>
</div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        v-for="x in agregando_horario.repeticion"
                        style="text-align:center;"
                      >
                        <input
                          v-model="x.value"
                          type="checkbox"
                          :true-value="1"
                          :false-value="0"
                        >
                      </td>
                    </tr>
                  </table>
                </td>
              </table>
              <div style="margin-bottom:20px">
                Anticipación necesaria (en horas) 
                <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
      Una persona puede agendar una cita sólo si hay más que ésta cantidad de hora entre ahora y la cita. Es para que no se agenden citas tan encima. 
    </div>
</div>
                <select v-model="anticipacion">
                  <option
                    v-for="v in 25"
                    :value="(v-1)*60"
                  >
                    {{ timeConvert((v-1)*60) }}
                  </option>
                </select>
              </div>    
              <div style="margin-bottom:20px">
                Anticipación mínima (en horas)
                <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
       Es la minima cantidad de horas que debe haber entre ahora y la cita que el paciente quiere agendar. Es para que los pacientes no agenden horas muy lejanas en el tiempo, con mucha anticipación. Dejándolo en 00:00 hace que se ignore este campo.
    </div>
</div>
                <select v-model="anticipacion_minima">
                  <option
                    v-for="v in 25"
                    :value="(v-1)*60"
                  >
                    {{ timeConvert((v-1)*60) }}
                  </option>
                </select>
              </div>
              <div style="margin-bottom:20px">
                Anticipación autoconfirmación (en horas)
                <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
        Si el paciente agenda con una anticipación igual o menor a esta, la hora queda "confirmada" automáticamente (en lugar de "citado"). Dejando en 00:00, esta opción no tiene efecto.
    </div>
</div>
                <select v-model="anticipacion_autoconfirmacion">
                  <option
                    v-for="v in 25"
                    :value="v-1"
                  >
                    {{ timeConvert(((v-1)*60)) }}
                  </option>
                </select>
              </div>
              <div style="margin-bottom:20px">
                Solo se puede agendar hasta (días antes y hora):
                <select v-model="anticipacion_fija_minima_dias">
                  <option
                    v-for="day in [0,1,2,3]"
                    :value="day"
                  >
                    {{ day }} Días antes
                  </option>
                </select>
                <select v-model="anticipacion_fija_minima_hora">
                  <option
                    v-for="v in 25"
                    :value="v-1"
                  >
                    A las {{ timeConvert(((v-1)*60)) }}
                  </option>
                </select>
              </div>
              <div style="margin-bottom:20px">
                Solo se puede agendar desde(días antes y hora):
                <select v-model="disponible_desde_dias">
                  <option
                    v-for="day in [0,1,2,3]"
                    :value="day"
                  >
                    {{ day }} Días antes
                  </option>
                </select>
                <select v-model="disponible_desde_hora">
                  <option
                    v-for="v in 25"
                    :value="v-1"
                  >
                    A las {{ timeConvert(((v-1)*60)) }}
                  </option>
                </select>
              </div>
              <div style="margin-bottom:20px">
                Prioridad
                <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
        Esta opción <b>sólo es útil cuando se quiere crear horarios superpuestos</b>. Se mostrarán primero los horarios con mayor prioridad, y cuando estos se llenen, se mostrarán los de prioridades menores.
    </div>
</div>
                <select v-model="prioridad">
                  <option
                    v-for="v in prioridades"
                    :value="v"
                  >
                    {{ v }}
                  </option>
                </select>
              </div>    
              <div style="margin-bottom:20px">
                Recargo (%)
                <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
      Es para crear horarios de sobrecupo con recargo. Se suma un % del total de la orden si se toma este horario.
    </div>
</div>
                <select v-model="recargo_porcentaje">
                  <option
                    v-for="v in recargos"
                    :value="v"
                  >
                    {{ v }}
                  </option>
                </select>
              </div>
              <div style="margin-bottom:20px">
                Recargo (monto)
                <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
      Es para crear horarios de sobrecupo con recargo. Se suma éste monto si se toma este horario.
    </div>
</div>
                <input
                  id=""
                  v-model="recargo_suma"
                  name=""
                >
              </div>
              Color                 <div style="margin-bottom:20px" class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
      En qué color se mostrará el horario. Sirve para algunos fronts.
    </div>
</div>
              <div style="display:inline-block; transform:translateY(8px);">
                <InputColorPicker v-model="color" />
              </div>
              {{ color }}
            </div>  
            <div style=" width:40%; vertical-align:top; margin-bottom:20px">
        Fecha inicio
        <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
      Representa la fecha a partir de la cual el horario entra en vigencia. Es el punto de inicio de la validez del horario.
    </div>
</div>
        <input
          v-model="fecha_inicio_checkbox"
          type="checkbox"
           style="display: inline-block;"
        >
        <input
            v-if="fecha_inicio_checkbox"
            style="display: inline-block;"
            id="x2"
            v-model="fecha_inicio_dt"
            type="date"
          >
      </div>
      <div style=" width:40%; vertical-align:top; margin-bottom:20px">
        Fecha término
        <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
      Indica hasta qué fecha el horario es válido. Si no se especifica, el horario no tiene una fecha de expiración definida.
    </div>
</div>

        <input
          v-model="fecha_termino_checkbox"
          type="checkbox"
           style="display: inline-block;"
        >
        <input
            v-if="fecha_termino_checkbox"
            style="display: inline-block;"
            id="x2"
            v-model="fecha_termino_dt"
            type="date"
          >
      </div>
            <div style="  vertical-align:top;">
              <h4>Comunas                 <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
      Para cuales comunas se mostrará este horario.
    </div>
</div></h4>
              <div>
                <input
                  v-model="comunas_buscadas"
                  type="text"
                >
                        
                <div style="margin:0; position:relative; text-align:center; width:200px;">
                  <div
                    v-if="comunas_buscadas != ''"
                    style="background:white; position:absolute; left:1px; border: 1px solid black; margin: 0; width:202px;"
                  >
                    <div
                      v-for="r in resultado_fuse_comuna.slice(0,10)"
                      style="cursor:pointer;"
                    >
                      <span @click="agregar_comunas(r.item.id)">{{ r.item.Nombre }}<b-icon
                        v-if="comunas_selected.includes(r.item.id)"
                        icon="check"
                      /> </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-for="c in comunas_selected">
                - {{ comunas.filter(x=>{return x.id == c})[0].Nombre }}
              </div>
            </div>
            <div style="vertical-align:top;">
              <h4>Categorías                 <div class="info-icon-container">
                  <i :style="{'color': prestador.admin.assets.colors.primary}" class="info-icon action-icon tf-icons ti ti-info-circle-filled"></i>
                  <!-- Replace with an actual icon as needed -->
    <div :style="{'background-color': prestador.admin.assets.colors.primary}" class="info-icon-container-popup">
      Este horario será visible para los pacientes que tengan servicios asociados a las siguientes categorías
    </div>
</div></h4>
              <table style="width:100%; margin: 0px 0px;">
                <tr style="background:black; color:white;">
                  <td>Id</td>
                  <td>Nombre</td>
                  <td>Habilitado</td>
                </tr>
                <tr v-if="categorias_usadas.includes(c.id)" v-for="c in editando_categorias">
                  <td style="padding:5px;">
                    {{ c.id }}
                  </td>
                  <td style="padding:5px;">
                    {{ c.nombre }}
                  </td>
                  <td style="text-align:center; padding:5px;">
                    <input
                      v-model="c.puede"
                      type="checkbox"
                    >
                  </td>
                </tr>
              </table>
            </div>
            <div style="text-align:center;">
              <button
                class="btn btn-success"
                style="margin: 10px auto; "
                :style="{'background-color':prestador.admin.assets.colors.primary}"
                @click="agregar_horario(profesional.id, agregando_horario.inicio, agregando_horario.termino, agregando_horario.repeticion)"
              >
                <b-icon
                  icon="plus-circle"
                  style="margin-right:6px;"
                  
                />Agregar horario
              </button>
            </div>
          </div>

</div>



  </div>

  </div>
  <div v-else>
   <Loader :height="100" :width="100" />

  </div>
</div>


</transition>
<div v-if="showing" class="background-screen"></div>
</div>

</template>

<script>
/* eslint-disable */

import {mapGetters, mapActions} from 'vuex';
import axios from 'axios';
import Fuse from 'fuse.js'
import InputColorPicker from 'vue-native-color-picker'
import Loader from '@/components/Loader.vue';


export default {
  name: 'ModalGenerico',
  components: {
   Loader,
   InputColorPicker,

  },
  props: {
   profesional_id:Number,

  },
  data(){
    return {
      agregando_horario:{inicio:-1, termino:-1, repeticion:[{value:0},{value:0},{value:0},{value:0},{value:0},{value:0},{value:0}]},
      dias:['Lu','Ma','Mi','Ju','Vi','Sa','Dom'],
      dias2:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
      periodo:60,
      periodos_posibles:[15,30,45,60,90,120],
      resultado_fuse_comuna:[],
      comunas_buscadas:'',
      comunas_selected:[],
      editando_examenes:[],
      categorias:[],
      editando_categorias:[],
      anticipacion:60,
      color:'#000000',
      anticipacion_minima:0,
      anticipacion_autoconfirmacion:0,
      anticipacion_fija_minima_dias:0,
      anticipacion_fija_minima_hora:0,
      disponible_desde_dias:0,
      disponible_desde_hora:0,
      nombre: '',

      prioridades:[1,2,3,4,5],
      prioridad:1,
      recargo_porcentaje:0,
      recargo_suma:0,
      recargos:[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],

      loaded:false,
      showing:false,
      estados : ['Anulado', 'Citado',  'Confirmado', 'Atendido', 'No Atendido'],

      fecha_inicio_checkbox: false,
      fecha_termino_checkbox: false,
      fecha_inicio_dt:'',
      fecha_termino_dt:'',
    }
  },
  methods:{
   agregar_comunas(cid){
        this.comunas_buscadas = ''
        if(!this.comunas_selected.includes(cid))
          this.comunas_selected.push(cid)
        else
          this.comunas_selected.splice(this.comunas_selected.indexOf(cid), 1)

    },
   initialize_(pid){

        this.loaded = false;
        this.editando_categorias = [];
        axios.post(window.hostname+'/api/admin/init_modificar_pro', 
        {id: pid})
        .then(response => 
        {
            this.loaded = true;
            this.profesional = response.data.profesional
            this.categorias = response.data.categorias

      for(var i = 0; i < this.categorias.length; i++){
        this.editando_categorias.push({id:this.categorias[i].id, nombre:this.categorias[i].nombre, puede:this.profesional.categorias.some(x=>{return x == this.categorias[i].id})})
      }

        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...")
        })
      
    },
   cambiarPuedeCategorias(){
      var ret = []
      for(var i = 0; i < this.categorias.length; i++){
        if (this.profesional.categorias.some(x=>{return x == this.categorias[i].id}) != this.editando_categorias.filter(x=>{return x.id == this.categorias[i].id})[0].puede)
          ret.push({id:this.categorias[i].id, puede: this.editando_categorias.filter(x=>{return x.id == this.categorias[i].id})[0].puede})
      }
      
      axios.post(window.hostname+'/api/admin/modificar_categorias_pro', 
      {id: this.profesional.id,
      categorias:ret})
      .then(response => 
      {          
          this.initialize_(this.$route.params.id);
      })

      .catch(e=>{

          console.log("Error al cargar...")
      })
      
    },

   timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
   agregar_horario(agenda_id, inicio, termino, repeticion){

var error_msg = ''
if(agenda_id == 0)
    error_msg = 'Por favor, ingrese una agenda. '
if(inicio == -1)
  error_msg += 'Por favor, ingrese una hora de inicio. '
if(termino == -1)
  error_msg += 'Por favor, ingrese una hora de término. '

if(inicio > termino)
  error_msg += 'La hora de inicio no puede ser mayor a la de término. '  
var x = '';
for (var i = 0; i < repeticion.length; i++)
  x += repeticion[i].value.toString();

if(x == '0000000')
   error_msg += 'Debes especificar al menos un día. '

let cat = []
for(var j = 0; j < this.editando_categorias.length; j++){
  if (this.editando_categorias[j].puede){
    cat.push(this.editando_categorias[j].id)
  }

}
if(cat.length == 0)
  error_msg = 'Debes agregar al menos una categoría'
if(this.comunas_selected.length == 0)
  error_msg = 'Debes agregar al menos una comuna'

if(error_msg == ''){

  let data_ =   {profesional_id: agenda_id,
    inicio: inicio,
    termino: termino,
    repeticion:x,
    categorias:cat,
    comunas:this.comunas_selected,
    anticipacion:this.anticipacion,
    anticipacion_minima:this.anticipacion_minima,
    anticipacion_autoconfirmacion:this.anticipacion_autoconfirmacion,
    anticipacion_fija_minima_dias:this.anticipacion_fija_minima_dias,
    anticipacion_fija_minima_hora:this.anticipacion_fija_minima_hora,
    disponible_desde_dias:this.disponible_desde_dias,
    disponible_desde_hora:this.disponible_desde_hora,
    prioridad:this.prioridad,
    recargo_porcentaje:this.recargo_porcentaje,
    recargo_suma:this.recargo_suma,
    intervalo:this.periodo,
    color:this.color,
    nombre:this.nombre,
    }
    if(this.fecha_inicio_checkbox && this.fecha_inicio_dt != '')
          {
            data_.fecha_inicio = this.fecha_inicio_dt
          }
          if(this.fecha_termino_checkbox && this.fecha_termino_dt != '')
          {
            data_.fecha_termino = this.fecha_termino_dt
          }
  axios.post(window.hostname+'/api/admin/addHorario', data_ )
  .then(resp=>{

    this.$notify({duration:5000, title: 'Horario agregado', text: '¡Se ha añadido el horario a la base de datos!', type: 'success'})

    this.$notify({
      group: 'foo',
      title: 'Horario agregado',
      text: '¡Se ha añadido el horario a la base de datos!',
      type:'success'
      });
      this.$root.$emit('recargar_profesionales');
      this.showing = false;
    /* this.initialize_(this.$route.params.id); */
  })
  .catch(e=>{ 
    this.$notify({duration:5000, title: '¡Error!',  group: 'foo', text: e.response.data.message, type: 'error'})
  })
}
else{
  console.log("LOL", error_msg)
  this.$notify({duration:5000, title: '¡Error!',  group: 'foo', text: error_msg, type: 'error'})
}
},




   send_to(route){      
      document.body.style.position = '';
      document.body.style.top = '';
      this.$router.push({path:route})
      this.hide();
    },
    show(){
      this.showing = true;
      /*document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${window.scrollY}px`;
      */
    },
    hide(){
      this.showing = false;
     /* document.body.style.position = '';
      document.body.style.top = '';
      */
    }


  },
  created(){
   
  },
  mounted(){
   
   this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
   console.log("OAISDHJ")
   this.initialize_(this.profesional_id);
   console.log("OAISDHJ")
  
  },
  computed:{
   ...mapGetters(['comunas', 'prestador', 'categorias_usadas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes']),

    profesionales(){

      let uniqueValues = this.ordenes.map((obj) =>  { return {id: obj.profesional.id, nombre:obj.profesional.nombre, apellido: obj.profesional.apellido}})
                                .filter((value, index, self) => self.findIndex(obj => obj.id == value.id) === index);
      return uniqueValues;
    },



  },
  watch:{
   comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
    profesional_id(val){
      this.initialize_(val)

    }
  
  },
}
</script>


<style scoped>
.button-modal-m{
   padding:10px 40px;
   border: 2px solid rgba(0, 0, 0, 1);
   color: rgba(0, 0, 0, 1);
   font-weight: 600;
   display: inline-block;
   width: 300px;
   margin:10px;
   border-radius: 3px;
   cursor: pointer;
}
.button-modal-m:hover{
   background-color: rgba(0, 0, 0, 1);
   transition: 0.3s;
   color:white;
   box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 10px 3px;
}
.background-screen{
  height: 100vh;
  width: 100vw;
  position: fixed;
 
/*   backdrop-filter: blur(2px); */
  z-index: 9999;

}
.titulo1{
  margin-bottom:30px;
  color: rgba(0, 0, 0, 1) !important;
}
.texto{
  text-align: center;
  padding: 0px 10px;
}
.main-m{
   display: flex;
   flex-wrap: wrap;
  width: 100%;
  text-align: center;


  overflow-y: scroll;
  max-height: Calc(100vh - 100px);
}
.filtro{
   text-align: left;
   background-color: rgb(250, 250, 250);
   border-radius: 15px;
   width: 100%;
   padding: 0px 10px;
}
.greeny{
  color:#FF8C00;
  cursor:pointer;
  text-decoration: underline;
}
.subtitulo2{
  color:#FF8C00;
}
#modal-receta-m{
   transition: 0.5s;
   position:fixed;



   overflow-y:auto; 
   border-radius:10px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:10px; 
   background:white; 
   top: 50%;
   left:0px;
    transform: translateY(-50%);
  
   width:700px;
   max-width:90%;
   margin: 0px auto; 
   right: 0;
   opacity:1;
   z-index:99999;
}
.flaco{
   width:600px !important;
   left: 10% !important;
   transform: translate(-10%, -50%) !important;
}
#modal-receta-m h2{
     text-align: center;
     font-weight: bold;
     color:rgba(0,0,0,0.6);
     margin-top:20px;
}
#modal-receta-m h4{

  color:rgb(0, 0, 0) !important;
  margin-bottom:15px;
  margin-top:15px;
  font-size: 20px;
  font-weight: 700;
}
#modal-receta-m h5{
  text-align: center;
  vertical-align: middle;
  color:rgba(0, 0, 0, 0.3) !important;
 
}
.cont{
  text-align: center;
  vertical-align: middle;
  position:absolute;
   width:Calc(100% - 40px);
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
#modal-receta-m .icon{
   height:40px;
   width:40px;
   color:white;
}
#modal-receta-m .contenedor-icono{
   cursor:pointer;
   position:absolute;
   right:20px;
   top:20px;
   height:40px;
   width:40px;
   background-color: rgba(0, 0, 0, 1);
   border-radius:50px;
   z-index: 100001;
}
#modal-receta-m .subtitulo{
   text-align: center;
   font-size:22px;
   margin-bottom:40px;
   margin-top:20px;
   color:rgb(181, 181, 181);
   font-weight:350;
}
#modal-receta-m .input-wrapper{
   padding:0px 5px;
}
#modal-receta-m .btn-examinar{
   background-color:#FF8C00;
   padding:8px 20px;
   font-size:14px;
   font-weight:600;
   width:200px;
   max-width:Calc(100vw - 80px);
   color:white !important;
   outline: none;
   margin-top:20px;
}
#modal-receta-m .draggable-container{
   padding: 15px 5px 20px 5px;
   max-width: 600px;
   margin: 0 auto;
   background-color: rgb(247, 247, 247);
   border-style:dashed;
   border: 2px dashed rgb(200, 200, 200);
   border-radius:5px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white;
   z-index: 999;
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 10px); 
   border: 1px solid rgb(200, 200, 200);
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:20px;
}
#modal-receta-m .receta-label-mobile{
   display: none;
}
#modal-receta-m .btn-enviar{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 1);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta-m .btn-enviar-d{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 0.4);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta-m .upload-img{
   height:70px;
   width:70px;
   margin:0 auto; 
   margin-bottom:40px;
   margin-top:30px;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:50%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding-right:7px;
}
#modal-receta-m .comuna{
   position:relative;
}
#modal-receta-m .input-container{
   max-width:610px;
   margin: 0 auto;
}
@media only screen and (max-width: 1000px) {
   #modal-receta-m .btn-enviar{
   margin:0 auto; 
   margin-top:30px; 
   cursor:pointer; 
   background-color:rgba(103,30,117,1);
   max-width:100%;
   margin: 20px 20px; 
   height:55px; 
   line-height:55px;
   color:white; 
   font-size:18px; 
   border-radius:4px;
   font-weight:500;
}
#modal-receta-m .btn-examinar{
   padding:10px 20px;
   font-size:18px;
   font-weight:600;
   width:300px;
   max-width:Calc(100vw - 80px);
   color:white !important;
}
#modal-receta-m .receta-label-mobile{
   display: inline-block;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:100%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding:5px 20px;
 color:grey;
 
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:30px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white; 
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 40px); 
}
#modal-receta-m .receta-label-desk{
   display: none;
}
#modal-receta-m .draggable-container{
   border:none;
   background: none;
}
#modal-receta-m .contenido{
   z-index: 999999;
   position:relative;
      overflow-y:scroll !important;
   min-height:Calc(100vh);
   -webkit-overflow-scrolling: touch;
}
#modal-receta-m{
   
   position:fixed !important;
   height:Calc(100vh) !important;
   border-radius:0px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:0px; 
   background:white; 
   top:0px !important;
   width: 100vw !important;
   transform: none !important;
   max-width:100%;
   max-height: 100%;
   margin: 0px auto !important; 
   left: 0px !important;
   right: 0px !important;
   opacity:1;
   z-index:90001;
}
.primary{
   display: block;
}
.texto{
  text-align: center;
}
}

.zoom-m-enter-active,
.zoom-m-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: zoom-m;
}

.zoom-m-leave-active {
  animation-direction: reverse;
}

@keyframes zoom-m {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
