<template>
  <!-- eslint-disable -->
   <div>
    <ModalVerDetalle ref="modalverdetalle" />
    <div class="main-n navbar-container-wrapper">

<div class="navbar-container" style="">
  <img class="logo-img" v-if="window.width <= 800" :src="'https://assets-prestador-clinicgo.s3.sa-east-1.amazonaws.com/'+prestador.id+'/images/logo.png'"  alt="Imagen">

  <div  v-if="window.width <= 800" v-on:click="CambiarMenuAction()" style="cursor: pointer; padding-left: 10px; z-index: 99999;">
    <div  id="hamburger" :class="{'isactive': sideMenu}" >
    <span></span>
    <span></span>
    <span></span>
</div>

  </div>
  <div class="sc-2">
  <div class="search-container">
    
            <input type="text" v-model="buscando_paciente" :placeholder=" window.width <= 800 ? 'Buscar' : 'Buscar paciente...'" class="search-input"><i style="position: absolute; right: 0px; top:50%; transform: translateY(-50%);" class="menu-icon tf-icons ti ti-search"></i>
        <div v-if="buscando_paciente != ''" class="clientes-popup no-cerrar">

          <div v-if="!cargando_pacientes">
            <div v-if="buscando_paciente.length > 4">
              <div v-if="pacientes_buscados.length > 0">
                <div v-on:click="$refs.modalverdetalle.show(s.orden)" class="seleccion-cliente no-cerrar"  v-for="s in pacientes_buscados.slice(0, 6)">
            <b-icon class="no-cerrar" :style="{'color': prestador.admin.assets.colors.primary}" icon="person"></b-icon>
            {{ s.cliente.nombre }} {{ s.cliente.apellido1 }} 
            
            <div class="fecha no-cerrar">
              {{ new Date(s.orden.fecha).toLocaleDateString("es-ES", { year: 'numeric', month: 'numeric', day: 'numeric' }) }}

            </div>
          </div>
              </div>
              <div style="padding: 20px;" v-else>
              <b>No se encontraron coincidencias...</b>

            </div>
            </div>


          </div>
          <div v-else>
            <Loader :height="100" :width="100" />
           
            
          </div>

        </div>
          </div>

  </div>


        <div  v-if="dropdownMenu && isLoggedIn"  class="dropdown-menu-x">
   
   <div v-if="!isLoggingOut">
   <div style="text-align:center; width:100%;">
<div class="numberCircle" :style="{'background': 'linear-gradient(45deg, '+prestador.admin.assets.colors.primary+', '+prestador.admin.assets.colors.secondary+')'}" style="margin: 20px auto;">{{admin_datos.nombre[0]}}</div>
</div>
<h4 style="font-size: 20px;"></h4>
<p style="font-size: 14px; color:rgba(17,17,17,0.6);"></p>

<hr />

<div style="padding: 10px 10px;">
Iniciado como {{admin_datos.nombre}} {{admin_datos.apellido}} <br/>
</div>
<p style="cursor:pointer; text-align:center;" class="linky" v-on:click="sendLogOut">Salir</p>
<hr />
<p style="font-size:13px; text-align:center;">Sobre CliniGo | Más información</p>

</div>
<div v-else style="min-height:445px;">
  <div class="loader-wrapper">

    <Loader :height="80" :width="80" /> <div>Cerrando sesión...</div>

   
  </div>
</div>

 </div>







 <div class="usuario">
      <div style="margin-right:20px;" @click.prevent="toggleDropdown"><div class="numberCircle" :style="{'background': 'linear-gradient(45deg, '+prestador.admin.assets.colors.primary+', '+prestador.admin.assets.colors.secondary+')'}">{{admin_datos.nombre[0]}}</div><div style=" display:inline-block;"> </div>  <b-icon icon="chevron-down" style="margin-left:5px; " class="editIcons" /></div>
    </div>



</div>

  

  



  

</div>
   </div>

  

</template>

<script>

import '@/assets/estilo_importado/vendor/css/rtl/core.css';
import '@/assets/estilo_importado/vendor/css/rtl/theme-default.css';
import '@/assets/estilo_importado/css/demo.css';

/* Vendor css */
import '@/assets/estilo_importado/vendor/libs/node-waves/node-waves.css';
import '@/assets/estilo_importado/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';
import '@/assets/estilo_importado/vendor/libs/typeahead-js/typeahead.css';


/* Icons */
import '@/assets/estilo_importado/vendor/fonts/fontawesome.css';
import '@/assets/estilo_importado/vendor/fonts/tabler-icons.css';
import {mapGetters, mapActions} from 'vuex';
import axios from 'axios'
import Loader from '@/components/Loader.vue';
import ModalVerDetalle from '@/components/utils/ModalVerDetalle.vue';

export default {
  
  name: 'TopNav',
  data(){
    return{
      cargando_pacientes:false,
      pacientes_buscados:[],
      buscando_paciente:'',
      dropdownMenu:false,
      isLoggingOut:false,
      buscando:'',
      resultados:[],
      mostrando: false,
      cargando:false,
       window: {
        width: 0,
        height: 0
      },
      mostrando_menu:false,
    }
  },
  components:{
    Loader,
    ModalVerDetalle,
  },
  watch:{
    buscando_paciente(val){
      if(val.length > 3){
        this.cargando_pacientes = true;
        axios.post(window.hostname+'/api/admin/buscar_paciente', 
        {string_:val})
        .then(response => 
        {
          this.cargando_pacientes = false;
          this.pacientes_buscados = response.data.clientes
          
        })

        .catch(e=>{
          if (e.response.data.message)
            this.resultado = e.response.data.message   
            this.cargando_pacientes = false;

        })
      }
    },


  },
    created () {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
    destroyed() {
    window.removeEventListener('resize', this.handleResize);

  },
  methods:{
    ...mapActions(['logoutAdmin', 'CambiarMenuAction']),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },



    sendLogOut(){
      this.isLoggingOut = true;
      setTimeout(()=>{this.isLoggingOut = false; this.logoutAdmin(); this.$router.push({name:'Home'})}, 1500)
    },
    toggleDropdown (e) {
      this.dropdownMenu = !this.dropdownMenu
    },
    close (e) {
      if (!this.$el.contains(e.target)) { /*Si clickeo (e.target) fuera de este componente (this.$el), se cierra*/
        if (!(e.target.classList.contains('no-cerrar')))
        {
          this.pacientes_buscados = []
          this.buscando_paciente = ''
        }
          
      }
    },
  },
    computed: {
  ...mapGetters(['admin_datos', 'isLoggedIn', 'sideMenu', 'prestador']),
  },
  mounted() {
    document.addEventListener('click', this.close)    
  },
  beforeDestroy () {
    document.removeEventListener('click',this.close)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.navbar-container-wrapper{
  position: sticky;
  top:0px;
  padding:20px 10px 00px 10px;
  top:0px; background-color: rgb(255,255,255,0.01); 
  left:0px; 
  width:Calc(100% - 0px); 
  backdrop-filter: saturate(200%) blur(6px);       
}
#hamburger {
    width: 30px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    
}

#hamburger span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: rgb(45,45,45);
    transition: all 0.3s ease;
}

.isactive span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.isactive span:nth-child(2) {
    opacity: 0;
}

.isactive span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -7px);
}

.logo-img{
  height:40px; position: absolute; top:50%; left:20px; transform: translateY(-50%);
}
.fecha{
  position: absolute;
  right:10px;
  top:50%;
  transform: translateY(-50%);
  font-size: 14px;
  background-color: rgb(245,245,245);
  padding: 2px 10px;
  border-radius: 4px;
}
.clientes-popup{

  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
  position: absolute;
  width: 400px;
  top:50px;
  background-color: white;
}
.seleccion-cliente{
 padding: 10px 10px;
 cursor: pointer;
 position: relative;
}
.seleccion-cliente:hover{
  background-color: rgb(250,250,250);
}
.numberCircle {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    padding: 8px;

    background: #00B4CC;
    border: 2px solid #fff;
    color: #fff;
    text-align: center;


    font-size: 18px;
}
.dropdown-menu-x{
   position: absolute;
   height:auto;
   width:300px;
   max-width:90%;
   right:0px;
   top:80px;
   z-index: 10000;
   background-color: white;  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3); border-radius: 8px;
}
.navbar-container{
  position: relative;

  display: flex;
    justify-content: space-between;
    align-items: center;
  width: 100%;
  max-width:1390px;
  margin: 0 auto;
  background-color: white; height: 70px; box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3); border-radius: 8px; position: relative;
  
}
.search-input {
    padding: 0.5rem 1rem;
    border: none;

    outline: none;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);  
}
.search-container{
  position: relative;
  padding-left: 30px;
}
.sc-2{
  position: absolute;
}


.search-btn {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    outline: none;
}

.search-btn:hover {
    color: #333;
}
.main-n{
  z-index: 10000;
  


}
.logo{
  height:100%;
  width:250px;
  display:block;
  float:left;  
}
.logo img{
  z-index: 9999;
}
.logo-c{
  height:100%;
  width:250px;
  position:absolute;
  top:0px;
  left:0px;
  z-index: -1;


}
.buscar-resultados{
  background: white;
  border: 1px solid rgba(0,0,0,0.2);
  width:300px;
  position: absolute;
  top: 56px;
  padding-bottom:15px;
  padding-top:15px;
  max-height:400px;
  overflow-y: scroll;

}

.buscandoIcons{
  color:#00B4CC;
  height:24px;
  width:24px;
  transform:translate(-3px, 3px);
}
.resultado{
  text-decoration: none;
  padding:5px 10px 5px 10px;
  /*border-top: 1px solid black;*/
}

.input-buscar{
  width:300px;
  border: 1px solid #00B4CC;
  border-top: none;
  border-right: none;
  border-left: none;

  padding: 15px 15px 15px 5px;
  height: 20px;
  outline: none;
}
.input-buscar::placeholder{
  color:rgba(0,0,0,0.4)
}
.buscar-barra{
  position:relative;
  padding: 25px 0;
  height:100%;
  vertical-align: middle;
  margin: auto;
  padding-left: 40px;
}
.logo img{
  margin-top:25px;
  margin-left:20px;
}
.usuario{
  position:absolute;
  right:4px;
  top:50%;
  transform: translateY(-50%);

  cursor: pointer;

}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.right{
  background-color: white;
  display:block;
  height:100%;
  width: Calc(100% - 250px);
  text-align: left;
  margin-left:0px;
  float:left;

}
.main-top-nav{
  position:fixed;
  height:80px;
  width:100%;
      -webkit-box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  background: white;
  z-index:999;
  top:0px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.numberCircle {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    padding: 8px;

    background: #00B4CC;
    border: 2px solid #fff;
    color: #fff;
    text-align: center;
    display:inline-block;

    font-size: 18px;
}


.loader-wrapper{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/*Icono hamburguesa*/
.hamburguesa{
  position: absolute;
  top:25px;
  left:120px;
  z-index: 10;
}
label{
 display:flex;
  flex-direction:column;
  width:30px;
  cursor:pointer;
}

label span{
  background: #fff;
  border-radius:10px;
  height:3px;
  margin: 3px 0;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

}


span:nth-of-type(1){
  width:50%;
  
}

span:nth-of-type(2){
  width:100%;
}


span:nth-of-type(3){
  width:75%;
 
}





.checkeado1{
  transform-origin:bottom;
  transform:rotatez(45deg) translate(3px,0px)
}


.checkeado2{
  
  transform-origin:top;
  transform:rotatez(-45deg)
}


.checkeado3{
  
  transform-origin:bottom;
  width:50%;
  transform: translate(7px,-7px) rotatez(45deg);

}
/*Termina Icono Hamburguesa*/


@media (max-width: 800px) {
  .search-container{
  position: relative;
  margin-left: 90px;
  
}
.clientes-popup{

box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
border-radius: 8px;
position: absolute;
width: Calc(100vw - 40px);
top:60px;
left:0px;
background-color: white;
}
.search-input{
  max-width: 120px;
  padding-left: 0px;
}
  .logo-img{
    left:50px;
  }
  .logo{
    width:220px;
  }
  .logo-c{
    width:220px;
  }
  .main-n{
    position: fixed;
    top:0px;
  }
}

.slide-enter-active,
.slide-leave-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: slide;
}

.slide-leave-active {
  animation-direction: reverse;
}

@keyframes slide {
  from {
    opacity: 1;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
  }
}
</style>
